import React from "react";
import {
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  DatePicker,
  TimePicker,
  AutoComplete as AutoCompleteComp,
} from "antd";
import { BottomIcon } from "../svg";
import Autocomplete from "react-google-autocomplete";
import moment from "moment";
import { PLACE_KEY } from "../constants";

const { TextArea } = Input;

export const RenderInput = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
        >
          <Input
            onClick={props.onClick}
            style={props.style}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readOnly === true ? true : false}
            maxLength={props.maxLength !== undefined ? props.maxLength : false}
            value={props.value}
          />
        </Form.Item>
      </Col>
    </>
  );
};
export const RenderTextArea = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
        >
          <TextArea
            style={{ height: "auto" }}
            rows={props.rows}
            onClick={props.onClick ? () => props.onClick() : null}
            placeholder={props.placeholder}
            disabled={props.disabled}
            readOnly={props.readOnly === true ? true : false}
            maxLength={props.maxLength !== undefined ? props.maxLength : false}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderPassword = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
        >
          <Input.Password placeholder={props.placeholder} />
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderSelect = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="form-group"
          initialValue={props.defaultValue}
          rules={props.rules}
        >
          <Select
            suffixIcon={<BottomIcon />}
            defaultValue={props.defaultValue}
            initialValue={props.initialValue}
            onChange={props.onChange}
            showSearch={props.showSearch}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onSearch={props.onSearch}
            onSelect={props.onSelect}
            filterOption={(input, option) => {
              const label = (option?.label ?? "").toLowerCase();
              const address = (option?.address ?? "").toLowerCase();
              const searchText = input.toLowerCase();
              const timeZoneSearch = (option?.value ?? "").toLowerCase();
              return label.includes(searchText) ||
                address.includes(searchText) ||
                timeZoneSearch.includes(searchText)
            }}
          >
            {props?.optionLabel?.map((item, i) => (
              <Select.Option key={i} value={item?._id} label={item?.name} address={item?.address}>
                {item?.address ? (
                  <>
                    <span style={{ fontWeight: "bold" }}>{item?.name}</span> <br />
                    <span style={{ fontSize: "15px" }}> {item?.address}</span>
                  </>
                ) : (
                  item?.name
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderCheckbox = (props) => {
  return (
    <>
      <Col
        xl={props.colWidth}
        className={props.colClassName}
        offset={props.coloffset}
      >
        <Form.Item
          name={props.checkboxvalue}
          labelCol={24}
          value={props.checkboxvalue}
        // label={props.formLabel}
        >
          <Checkbox
            name={props.checkboxvalue}
            value={props.checkboxvalue}
            onChange={props.onChange}
          >
            {props.formLabel}
          </Checkbox>
        </Form.Item>
      </Col>
    </>
  );
};

export function LocationSearchInput({
  setLat,
  setLng,
  disabled,
  locationAddress,
  setLocationAddress,
  form,
  col,
  name,
  label,
  rules,
  // setCountry,
  setState,
  setCity,
  setZipCode,
}) {
  return (
    <Col {...col}>
      <Form.Item
        name={name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={label}
        rules={rules}
      >
        <Autocomplete
          value={locationAddress}
          placeholder="Address"
          disabled={disabled}
          className="ant-input w-100"
          onChange={(e) => setLocationAddress(e.target.value)}
          apiKey={PLACE_KEY}
          options={{
            types: [],
            componentRestrictions: { country: "us" },
            // fields: ['ALL']
          }}
          onPlaceSelected={(place) => {
            const loc = place?.formatted_address.split(", ");

            form.setFieldsValue({
              address: place.formatted_address,
            });
            setState(
              place?.address_components?.find((type) =>
                type.types.includes("administrative_area_level_1")
              )?.long_name
            );
            setCity(
              place?.address_components?.find((type) =>
                type.types.includes("locality")
              )?.long_name
            );
            setZipCode(
              place?.address_components?.find((type) =>
                type.types.includes("postal_code")
              )?.long_name
            );
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setLocationAddress(loc.toString());
          }}
        />
      </Form.Item>
    </Col>
  );
}

export const RenderDatePicker = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={props.value}
          className="form-group"
          rules={props.rules}
        >
          <DatePicker
            value={props.value}
            format={props.format}
            name={props.name}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            onChange={props.onChange}
            allowClear={false}
            placeholder={props.placeholder}
            disabledDate={(current) => {
              let customDate = moment().format(props.format);
              return current && current < moment(customDate, props.format);
            }}
            onWheel={props.onWheel}
            disabled={props.disabled}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderTimePicker = (props) => {
  const disabledTime = () => {
    return {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 19, 20, 21, 22, 23],
      disabledMinutes: (selectedHour) => {
        if (selectedHour === 18) {
          return Array.from({ length: 60 }, (_, minute) => minute).filter(
            (minute) => minute !== 0
          );
        }
        return [];
      },
    };
  };
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValue={props.value}
          className="form-group"
          rules={props.rules}
        >
          <TimePicker
            use12Hours
            value={props.value}
            format={props.format}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            allowClear={false}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onWheel={props.onWheel}
            showNow={false}
          // disabledTime={disabledTime} //Temporary remove time restriction for testing 
          />
        </Form.Item>
      </Col>
    </>
  );
};

export function CitySearchInput({
  disabled,
  locationAddress,
  setLocationAddress,
  form,
  col,
  name,
  label,
  rules,
  setState,
  setCity,
  setLat,
  setLng,
  city,
  placeholder,
}) {
  return (
    <Col {...col}>
      <Form.Item
        name={name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={label}
        rules={rules}
      >
        <Autocomplete
          value={city}
          disabled={disabled}
          className="ant-input w-100"
          placeholder={placeholder}
          onChange={(e) => setCity(e.target.value)}
          apiKey={PLACE_KEY}
          options={{
            types: ["(cities)"],
            componentRestrictions: { country: "us" },
            // fields: ['ALL']
          }}
          onPlaceSelected={(place) => {

            form.setFieldsValue({
              city: place?.address_components?.find((type) =>
                type.types.includes("locality")
              )?.long_name,
            });
            setState(
              place?.address_components?.find((type) =>
                type.types.includes("administrative_area_level_1")
              )?.long_name
            );
            setCity(
              place?.address_components?.find((type) =>
                type.types.includes("locality")
              )?.long_name
            );
          }}
        />
      </Form.Item>
    </Col>
  );
}

export const SearchInput = (props) => {
  return (
    <Col {...props.col}>
      <Form.Item
        name={props.name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={props.label}
        rules={props.rules}
      >
        <Input
          onChange={props.onChange}
          onClick={props.onClick}
          style={props.style}
          placeholder={props.placeholder}
          disabled={props.disabled}
          readOnly={props.readOnly === true ? true : false}
          maxLength={props.maxLength !== undefined ? props.maxLength : false}
          value={props.value}
        />
      </Form.Item>
    </Col>
  );
};

export const RenderAutoComplete = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="form-group"
          initialValue={props.defaultValue}
          rules={props.rules}
          key={props.options.length}
        >
          <AutoCompleteComp
            value={props.value}
            options={props.options}
            onSelect={props.onSelect}
            onSearch={props.onSearch}
            onChange={props.onChange}
            placeholder={props.placeholder}
            filterOption={true}
            disabled={props.disabled}
          ></AutoCompleteComp>
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderSelectForTimeZone = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="form-group"
          initialValue={props.defaultValue}
          rules={props.rules}
        >
          <Select
            suffixIcon={<BottomIcon />}
            defaultValue={props.defaultValue}
            initialValue={props.initialValue}
            onChange={props.onChange}
            showSearch={props.showSearch}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onSearch={props.onSearch}
            onSelect={props.onSelect}
            filterOption={(input, option) => {
              const label = (option?.label ?? "").toLowerCase();
              const searchText = input.toLowerCase();
              const timeZoneSearch = (option?.value ?? "").toLowerCase();
              return label.includes(searchText) ||
                timeZoneSearch.includes(searchText)
            }}
          >
            {props?.optionLabel?.map((item, i) => (
              <Select.Option key={i} value={item?.value} label={item?.label}>
                {item?.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};